export interface CookieBanner extends Element {
  togglePrivacyShield: Function;
  getConsents: Function;
  getConsentsByCategory: Function;
  changeLanguage: Function;
}

export interface CookieBannerEntry {
  name: string;
  enabled: boolean;
  slug: string;
  userAccepted: boolean;
}

export function initCookieLanguage () {
  document.addEventListener('usercentrics-consents-loaded', () => {
    const banner = document.querySelector('cmm-cookie-banner') as CookieBanner;
    banner?.changeLanguage(document.documentElement.lang);
  }, false);
}
